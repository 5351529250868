.banner-container {
  height: 40px;
  display: flex;
  overflow: hidden;
  align-items: center;
}

.banner-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.banner-track {
  position: absolute;
  display: flex;
  height: 100%;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 30s linear infinite;
  width: 2000px;
  text-align: center;
  flex-wrap: nowrap;
}

.track-piece {
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
  padding: 0 50px;
  height: 40px;
  line-height: 40px;
  flex-shrink: 0;
  color: var(--lm-alice-blue);
}

.track-spacer {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  min-width: 410px;
}

.zig-zag {
  background: rgb(247,17,24);
  background: linear-gradient(90deg, rgba(247,17,24,1) 0%, rgba(255,255,255,1) 25%, rgba(0,31,163,1) 100%);
}

.checkers {
  background: rgb(247,17,24);
  background: linear-gradient(90deg, rgba(247,17,24,1) 0%, rgba(255,255,255,1) 50%, rgba(0,31,163,1) 100%);
}

.dots {
  background: rgb(247,17,24);
  background: linear-gradient(90deg, rgba(247,17,24,1) 0%, rgba(255,255,255,1) 81%, rgba(0,31,163,1) 100%);
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
