footer {
  height: 298px;
  width: 100%;
  background: var(--lm-neon);
  display: flex;
  justify-content: space-between;
  padding: 2rem 1rem;
  flex-direction: column;

  @media (min-width: var(--breakpoint-md)) {
    flex-direction: row;
  }

  .form-title {
    font-size: 1.5rem;
    color: var(--lm-blue);
    font-weight: 700;
    margin-bottom: 20px;
  }
}

.footer-wrapper {
  width: 70%;
  padding: 0 12px 0 22px;
  display: flex;
  justify-content: space-between;
}

.footer-options {
  width: 100%;
  list-style: none;

  @media (min-width: var(--breakpoint-md)) {
    width: 30%;
  }

  li {
    margin-bottom: 10px;
    list-style: none;
  }
}

.footer-options a {
  color: black;
  font-size: 1.25rem;
  text-decoration: none;
  font-weight: 600;
}

.contact-form-wrapper {
  form {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: var(--breakpoint-md)) {
      width: 625px;
    }
  }

  input,
  textarea {
    box-sizing: border-box;
    border-radius: 0;
    padding: 0 0 0 16px;
    height: 40px;
    text-align: left;
    color: rgb(0, 0, 0);
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid black;
    margin-bottom: 20px;
  }
}
