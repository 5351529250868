:root {
  --lm-neon: #e4fe22;
  --lm-purple: #c091c6;
  --lm-blue: #1e90ff;
  --lm-green: #01986e;
  --lm-off-white: #f7ede7;
  --lm-white: #ffffffff;
  --lm-off-red-rgb: #f71118ff;
  --lm-cornflower-blue: #629deeff;
  --lm-zaffre: #001fa3ff;
  --lm-alice-blue: #d8ebf4ff;
  --breakpoint-xs: 575px; /* Extra Small Devices (Phones) */
  --breakpoint-sm: 767px; /* Small Devices (Tablets in Portrait Mode) */
  --breakpoint-md: 991px; /* Medium Devices (Tablets in Landscape Mode) */
  --breakpoint-lg: 1199px; /* Large Devices (Laptops and Desktops) */
  --breakpoint-xl: 1399px; /* Extra Large Devices (Large Desktops) */
  --breakpoint-xxl: 1400px; /* Extra Extra Large Devices (Widescreens) */
}

* {
  box-sizing: border-box;
}

body {
  font-family: Inter, sans-serif;
}

h1 {
  font-size: 2.5rem;
  text-align: center;
  color: #663596;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

img {
  width: 100%;
  height: 100%;
}

.page-content {
  position: relative;
  background: rgb(216,235,244);
  background: radial-gradient(circle, rgba(216,235,244,1) 0%, rgba(255,255,255,1) 50%, rgba(98,157,238,1) 100%);
  height: 100%;
  width: 100%;
  min-height: 100vh;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.sc-widget-wrapper {
  max-width: 320px;
}

.site-counter {
  position: fixed;
  bottom: 75px;
  left: 16px;
}

.ad-placement {
  width: 120px;
  height: 600px;
}

.tones-gif {
  width: 300px;
  height: 200px;
}

.two-col {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: var(--breakpoint-md)) {
    flex-direction: row;
  }
}

.left,
.right {
  height: 370px;
}
