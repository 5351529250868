.media-player-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.media-player-top-display {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 36px;
  background: transparent;
  color: var(--lm-white);
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  padding: 8px;
}

.sound-cloud-iframe-wrapper {
  position: relative;
  width: 100%;
  background: var(--lm-off-red-rgb);
}

.loader-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: radial-gradient(farthest-side, var(--lm-alice-blue) 94%, transparent) top/8px 8px no-repeat,
    conic-gradient(transparent 30%, var(--lm-alice-blue));
    mask: radial-gradient(farthest-side, transparent calc(100% - 8px), black 0);
    -webkit-mask: radial-gradient(farthest-side, transparent calc(100% - 8px), black 0);
    animation: l13 1s infinite linear;
}

@keyframes l13 {
    100% {
        transform: rotate(1turn);
    }
}

.blink-me {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}
