.main-logo-wrapper {
  display: flex;
  width: calc(100% + 2.5rem);
  overflow: hidden;
  background: var(--lm-off-red-rgb);
  margin-left: -2.5rem;
}

.main-logo-text {
  position: relative;
  font-weight: 800;
  font-size: 2rem;
  color: var(--lm-white);
  padding-right: 1.5rem;
  flex-shrink: 0;

  @media (min-width: var(--breakpoint-md)) {
    font-size: 12rem;
  }

  svg {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    display: block;
    vertical-align: middle;
  }

  .logo-image-wrapper {
    width: 634px;
    height: 111px;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.word-wrapper-left {
  transform: translateX(0%);
  transform-origin: 356.995px 91px;
}

.word-wrapper-right {
  transform: translateX(0%);
  transform-origin: 1110.46px 96.6px;
}
