nav {
  height: 45px;
  width: 100%;
  background-color: var(--lm-zaffre);
  display: flex;
  text-transform: uppercase;
  font-weight: 600;
  align-items: center;
  border-bottom: 1px solid;
  font-size: 1rem;
  position: relative;
}

.top-nav-wrapper {
  width: 100%;
  padding: 0 12px 0 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.spinning-logo {
  height: 20px;
  width: 20px;
}

.top-nav-links a {
  color: var(--lm-white);
  font-size: 1rem;
  text-decoration: none;
  margin-right: 20px;

  @media (min-width: var(--breakpoint-md)) {
    font-size: 2rem;
  }
}

.top-nav-links a:hover {
  color: var(--lm-off-red-rgb);
  text-decoration: underline;
}
