.helper-wrapper {
    z-index: 99999999999;
    bottom: 4rem;
    right: 2rem;
    width: 400px;
    max-width: calc(100% - 2rem);
    position: fixed;

    @media (min-width: var(--breakpoint-md)) {
        bottom: 4rem;
        right: 2rem;
    }
}

.helper-copy {
    background: white;
    font-family: arial, sans-serif;
    filter: drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.6));
    padding: 1rem;
    border-radius: 4rem;
    float: left;
    width: 180px;
    max-width: 100%;

    @media (min-width: var(--breakpoint-md)) {
        width: 300px;
        padding: 2rem;
    }

    & .text {
        filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.3));
    }
}

img.helper-profile {
    width: 100%;
    height: 100%;
    clip-path: circle();
}

.helper-icon {
    width: 80px;
    height: 80px;
    filter: drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.6));
    margin-left: 0.8rem;
    float: right;

    @media (min-width: var(--breakpoint-md)) {
        width: 100px;
        height: 100px;
        margin-left: 0.5rem;
    }
}